<template>
  <div id="app">
    <JobSearch />
  </div>
</template>

<script>
import JobSearch from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    JobSearch,
  },
};
</script>
