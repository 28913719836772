<template>
  <div class="job-search">
    <h1>Job Search</h1>

    <!-- Form for Title and Location -->
    <form @submit.prevent="handleSearch">
      <div class="form-group">
        <label for="title">Job Title:</label>
        <input
          type="text"
          id="title"
          v-model="filters.title"
          placeholder="Enter job title"
        />
      </div>

      <div class="form-group">
        <label for="location">Location:</label>
        <input
          type="text"
          id="location"
          v-model="filters.location"
          placeholder="Enter location"
        />
      </div>

      <button type="submit">Search</button>
    </form>

    <!-- Loading Indicator -->
    <div v-if="loading" class="loading">Loading jobs...</div>

    <!-- Job List -->
    <div v-else>
      <p v-if="jobData">Total Jobs Found: {{ jobData.count }}</p>

      <div v-if="jobData && jobData.results.length" class="job-list">
        <div v-for="job in jobData.results" :key="job.id" class="job-card">
          <h3>{{ job.title }}</h3>
          <p><strong>Company:</strong> {{ job.company }}</p>
          <p><strong>Location:</strong> {{ job.location }}</p>
          <p><strong>Description:</strong> {{ job.description }}</p>
          <p><strong>Posted:</strong> {{ job.time_since }}</p>
          <a :href="job.url" target="_blank">View Job Details</a>
        </div>
      </div>

      <div v-else>No jobs found. Try another search.</div>

      <!-- Pagination -->
      <div class="pagination" v-if="jobData && (jobData.next || jobData.previous)">
        <button :disabled="!jobData.previous" @click="fetchJobs(jobData.previous)">Previous</button>
        <button :disabled="!jobData.next" @click="fetchJobs(jobData.next)">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filters: {
        title: "Software Engineer", // Default value
        location: "colombo", // Default value
      },
      jobData: null,
      loading: true,
      error: null,
    };
  },
  methods: {
    fetchJobs(url) {
      this.loading = true;
      this.error = null;

      axios
        .get(url)
        .then((response) => {
          this.jobData = response.data;
        })
        .catch((error) => {
          this.error = "Failed to load jobs. Please try again.";
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      const { title, location } = this.filters;
      const apiUrl = `https://api-jobs-dev.talentbase.lk/api/jobs/?q=${encodeURIComponent(
        title
      )}&location=${encodeURIComponent(location)}`;
      this.fetchJobs(apiUrl);
    },
  },
  mounted() {
    this.handleSearch(); // Perform initial search
  },
};
</script>

<style>
.job-search {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.form-group {
  margin-bottom: 15px;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.job-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.job-card h3 {
  margin: 0;
  color: #007bff;
}
.job-card a {
  color: #007bff;
  text-decoration: none;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.loading {
  text-align: center;
}
</style>
